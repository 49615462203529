import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  Stack,
  useToast,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  Input,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import ShippingModal from '../Shipment/ShipmentModal.js';
import FirstOrderList from './FirstOrderList';
import OrderDetailsModal from './Orders/OrderDetailsModal.js';
import './SellerDashBoard.css';
import { generatePdf, printOrder } from './lib/GenerateDocumentPage.js';
import { FaCheck, FaTimes } from 'react-icons/fa';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const SellerDashboard = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShippingModalOpen, setIsShippingModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const toast = useToast();

  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const [filters, setFilters] = useState({
    query: '',
    status: '',
    type: '',
    startDate: oneMonthAgo.toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0],
  });

  useEffect(() => {
    fetchOrders(currentPage);
  }, [currentPage]);

  const fetchOrders = async (page = 1) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/orders?page=${page}&limit=10`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setOrders(data.orders.reverse() || []);
        setFilteredOrders(data.orders || []);
        setTotalPages(data.totalPages || 1);
      } else {
        console.error('Failed to fetch orders');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const fetchFilteredOrders = async (filters, page = 1) => {
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page,
        limit: 10,
      }).toString();

      const response = await fetch(`${API_BASE_URL}api/orders/search?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setFilteredOrders(data.orders || []);
        setTotalPages(data.totalPages || 1);
      } else {
        console.error('Failed to fetch filtered orders');
      }
    } catch (error) {
      console.error('Error fetching filtered orders:', error);
    }
  };

  const handleUpdateStatus = async (orderId, status) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/orders/${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ status }),
      });

      if (response.ok) {
        const updatedOrder = await response.json();
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === orderId ? { ...order, status: updatedOrder.status } : order
          )
        );
        setFilteredOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === orderId ? { ...order, status: updatedOrder.status } : order
          )
        );
        toast({
          title: 'Status updated',
          description: 'Order status has been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Failed to update order status');
        toast({
          title: 'Error',
          description: 'Failed to update order status.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      toast({
        title: 'Error',
        description: 'Failed to update order status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleApproveBySeller = async (orderId) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/orders/approve-seller/${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const updatedOrder = await response.json();
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === orderId ? { ...order, status: updatedOrder.status } : order
          )
        );
        setFilteredOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === orderId ? { ...order, status: updatedOrder.status } : order
          )
        );
        toast({
          title: 'Order approved',
          description: 'Order has been approved successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        const errorData = await response.json();
        console.error('Failed to approve order:', errorData.message);
        toast({
          title: 'Error',
          description: `Failed to approve order: ${errorData.message}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error approving order:', error);
      toast({
        title: 'Error',
        description: 'Failed to approve order.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const applyFilters = () => {
    fetchFilteredOrders(filters, currentPage);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Box p={4} className="seller-dashboard-container">
      <Heading mb={4} className="seller-dashboard-heading">
        Vendedor DashBoard - Todos os Pedidos
      </Heading>
      <Flex mb={4}>
        <Input
          name="query"
          placeholder="Nome, telefone ou pedido"
          maxWidth="200px"
          mr={4}
          onChange={handleFilterChange}
        />
        <Select
          name="status"
          placeholder="Status"
          maxWidth="150px"
          mr={4}
          onChange={handleFilterChange}
        >
          {['aprovado', 'aguardando_aval_gestor', 'aguardando_aval_vendedor', 'rejeitado_gestor', 'rejeitado_vendedor'].map(
            (status) => (
              <option key={status} value={status}>
                {status}
              </option>
            )
          )}
        </Select>
        <Select
          name="type"
          placeholder="Tipo"
          maxWidth="150px"
          mr={4}
          onChange={handleFilterChange}
        >
          {/* Add options dynamically */}
        </Select>
        <Input
          name="startDate"
          type="date"
          maxWidth="200px"
          mr={4}
          value={filters.startDate}
          onChange={handleFilterChange}
        />
        <Input
          name="endDate"
          type="date"
          maxWidth="200px"
          mr={4}
          value={filters.endDate}
          onChange={handleFilterChange}
        />
        <Button onClick={applyFilters}>Filtrar</Button>
      </Flex>
      <Text mb={4} className="seller-dashboard-info-text">
        Durante o período de 18h15 às 21h45 não é permitida a extração de dados em massa.
      </Text>
      <Table variant="striped" className="seller-dashboard-table">
        <Thead>
          <Tr>
            <Th>Pedido</Th>
            <Th>Status</Th>
            <Th>Data</Th>
            <Th>Cliente</Th>
            <Th>Telefone</Th>
            <Th>Email</Th>
            <Th>Pagamento</Th>
            <Th>Total</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredOrders.map((order) => (
            <Tr key={order._id}>
              <Td>
                <Button variant="link" colorScheme="blue" onClick={() => handleOpenModal(order)}>
                  #{order._id}
                </Button>
              </Td>
              <Td>{order.status}</Td>
              <Td>{new Date(order.createdAt).toLocaleString()}</Td>
              <Td>{order.userId && order.userId.name ? order.userId.name : ''}</Td>
              <Td>{order.userId && order.userId.phone ? order.userId.phone : ''}</Td>
              <Td>{order.userId && order.userId.email ? order.userId.email : ''}</Td>
              <Td>{order.paymentMethod || ''}</Td>
              <Td>
                R$
                {order.items
                  ? order.items
                      .reduce((total, item) => total + (item.price ? item.price * item.quantity : 0), 0)
                      .toFixed(2)
                  : '0.00'}
              </Td>
              <Td>
                <Stack direction="row" spacing={4}>
                  {(order.status === 'pendente' || order.status === 'aguardando_aval_vendedor') && (
                    <>
                      <Button size="sm" className="approve-button" onClick={() => handleApproveBySeller(order._id)}>
                        <FaCheck />
                      </Button>
                      <Button
                        size="sm"
                        className="reject-button"
                        onClick={() => handleUpdateStatus(order._id, 'rejeitado_vendedor')}
                      >
                        <FaTimes />
                      </Button>
                    </>
                  )}
                </Stack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Flex justifyContent="space-between" mt={4}>
        <Button onClick={goToPreviousPage} isDisabled={currentPage === 1}>
          Anterior
        </Button>
        <Button onClick={goToNextPage} isDisabled={currentPage === totalPages}>
          Próximo
        </Button>
      </Flex>

      <FirstOrderList />

      <OrderDetailsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        order={selectedOrder}
        onOpenShippingModal={() => setIsShippingModalOpen(true)}
      />

      <ShippingModal
        isOpen={isShippingModalOpen}
        onClose={() => setIsShippingModalOpen(false)}
        orderTotal={
          selectedOrder
            ? selectedOrder.items.reduce((total, item) => total + (item.price ? item.price * item.quantity : 0), 0)
            : 0
        }
        orderId={selectedOrder ? selectedOrder._id : ''}
        API_BASE_URL={API_BASE_URL}
      />
    </Box>
  );
};

export default SellerDashboard;
