import React from 'react';
import { BrowserRouter as  Router, Route, Routes, Link } from 'react-router-dom';
import { Box, Flex, VStack, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Button, Text } from '@chakra-ui/react';
import { FaUser,FaShoppingCart, FaLink, FaHome } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { MdManageAccounts } from "react-icons/md";
import { RiCarouselView } from "react-icons/ri";
import ManageProducts from '../Product/ProductManager';
import SettingsPage from '../Settings/SettingsPage';
import TextTypeManager from '../TextType/TextTypeManager';
import ContactManager from '../ContactForm/ContactManager';
import ManageItems from './ManageItems';
import ManageAttributes from './ManageAttributes';
import ManageFooterLinks from './ManageFooterLinks';
import ManagePages from './ManagePages';
import ManageNavItems from './ManageNavBar';
import LogoUpload from '../Settings/UploadLogo';
import ManageStock from './ManageStock';
import UserManagement from './UserManagement';
import SellerDashboard from '../Seller/SellerDashBoard';
import ManageUpperCarousel from './ManageUpperCarousel';
import ManageRelatedProducts from '../Product/UpperCarouselProduct';
import SellerManagerDashboard from '../Seller/SellerManagerDashBoard';
import BI_Dashboard from '../Business Intelligence/BI';
import Dashboard from './Dashboard/DashBoard';
import ManageAboutUs from '../TermsConditions/ManageAboutUs';
import ManageHelp from '../TermsConditions/ManageHelp';
import ManagePrivacyTerms from '../TermsConditions/ManagePrivacyTerms';
import ManageMeasureTables from './ManageMeasureTables';

const AdminDashboard = ({ setCustomTheme, logoUrl, updateLogo }) => {
  return (
      <Flex>
        <Flex
          as="nav"
          direction="column"
          bg="gray.800"
          color="white"
          width="300px"
          p={4}
          minH="100vh"
          boxShadow="md"
        >
          <Box mb={6}>
            <Heading size="md">Área Administrativa</Heading>
            <Link to="/admin/">
              <Button variant="link" colorScheme="white" leftIcon={<FaHome />}>
                Home/Dashboard
              </Button>
            </Link>
          </Box>
          <VStack align="stretch" spacing={4} width="100%">
            <Accordion allowMultiple>
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Flex align="center">
                      <FaShoppingCart mr={2} />
                      <Text ml={2}>Produtos</Text>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Link to="/admin/items">
                    <Button variant="link" colorScheme="whiteAlpha">Controle de Categorias</Button>
                  </Link>
                  <Link to="/admin/attributes">
                    <Button variant="link" colorScheme="whiteAlpha">Controle de Atributos</Button>
                  </Link>
                  <Link to="/admin/products">
                    <Button variant="link" colorScheme="whiteAlpha">Controle de Produtos</Button>
                  </Link>
                  <Link to="/admin/stock">
                    <Button variant="link" colorScheme="whiteAlpha">Controle de Estoque</Button>
                  </Link>
                  <Link to="/admin/measure-table">
                    <Button variant="link" colorScheme="whiteAlpha">Controle das Tabelas de Medidas</Button>
                  </Link>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Flex align="center">
                      <RiCarouselView mr={2} />
                      <Text ml={2}>Carrossel</Text>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Link to="/admin/carousel">
                    <Button variant="link" colorScheme="whiteAlpha">Controle de Carrossel</Button>
                  </Link>
                  <Link to="/admin/carouselmanager">
                    <Button variant="link" colorScheme="whiteAlpha">Controle de Itens Carrossel</Button>
                  </Link>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Flex align="center">
                      <FaLink  mr={2} />
                      <Text ml={2}>Navegação</Text>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Link to="/admin/navitems">
                    <Button variant="link" colorScheme="whiteAlpha">Manage Nav Items</Button>
                  </Link>
                  <Link to="/admin/footerlinks">
                    <Button variant="link" colorScheme="whiteAlpha">Controle Links Rodapé</Button>
                  </Link>
                  <Link to="/admin/pages">
                    <Button variant="link" colorScheme="whiteAlpha">Controle Páginas Rodapé</Button>
                  </Link>
                  <Link to="/admin/texttypes">
                    <Button variant="link" colorScheme="whiteAlpha">Controle de Textos</Button>
                  </Link>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Flex align="center">
                      <FaUser mr={2} />
                      <Text ml={2}>Usuários</Text>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Link to="/admin/users">
                    <Button variant="link" colorScheme="whiteAlpha">Controle de Usuários</Button>
                  </Link>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Flex align="center">
                      <IoSettingsSharp  mr={2} />
                      <Text ml={2}>Settings</Text>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Link to="/admin/contacts">
                    <Button variant="link" colorScheme="whiteAlpha">Controle de Contatos</Button>
                  </Link>
                  <Link to="/admin/settings">
                    <Button variant="link" colorScheme="whiteAlpha">Configurações de Cor e Tema</Button>
                  </Link>
                  <Link to="/admin/uploadlogo">
                    <Button variant="link" colorScheme="whiteAlpha">Configurar Logo</Button>
                  </Link>
                  <Link to="/admin/aboutus">
                    <Button variant="link" colorScheme="whiteAlpha">Controle do Sobre Nós</Button>
                  </Link>
                  <Link to="/admin/help">
                    <Button variant="link" colorScheme="whiteAlpha">Controle do Ajuda</Button>
                  </Link>
                  <Link to="/admin/privacy">
                    <Button variant="link" colorScheme="whiteAlpha">Controle do Política de Privacidade</Button>
                  </Link>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Flex align="center">
                      <MdManageAccounts mr={2} />
                      <Text ml={2}>Vendedor</Text>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Link to="/admin/seller-dashboard">
                    <Button variant="link" colorScheme="whiteAlpha">Área dos Vendedores</Button>
                  </Link>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Flex align="center">
                      <MdManageAccounts mr={2} />
                      <Text ml={2}>Gerente</Text>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Link to="/admin/sellermanager-dashboard">
                    <Button variant="link" colorScheme="whiteAlpha">Área Gerente Vendas</Button>
                  </Link>
                  <Link to="/admin/bi_dashboard">
                    <Button variant="link" colorScheme="whiteAlpha">BI DashBoard</Button>
                  </Link>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Link to="/">
              <Button variant="link" colorScheme="whiteAlpha">Voltar para o Site</Button>
            </Link>
          </VStack>
        </Flex>
        <Box flex="1" p={6} bg="gray.50">
          <Box bg="white" p={6} boxShadow="md" borderRadius="md">
            <Routes>
              <Route path="/" element={<Dashboard/>} />
              <Route path="products" element={<ManageProducts />} />
              <Route path="measure-table" element={<ManageMeasureTables />} />
              <Route path="settings" element={<SettingsPage setCustomTheme={setCustomTheme} />} />
              <Route path="items" element={<ManageItems />} />
              <Route path="aboutus" element={<ManageAboutUs />} />
              <Route path="help" element={<ManageHelp />} />
              <Route path="privacy" element={<ManagePrivacyTerms />} />
              <Route path="attributes" element={<ManageAttributes />} />
              <Route path="products" element={<ManageProducts />} />
              <Route path="stock" element={<ManageStock />} />
              <Route path="texttypes" element={<TextTypeManager />} />
              <Route path="contacts" element={<ContactManager />} />
              <Route path="footerlinks" element={<ManageFooterLinks />} />
              <Route path="pages" element={<ManagePages />} />
              <Route path="users" element={<UserManagement />} />
              <Route path="navitems" element={<ManageNavItems />} />
              <Route path="seller-dashboard" element={<SellerDashboard />} />
              <Route path="sellermanager-dashboard" element={<SellerManagerDashboard />} />
              <Route path="bi_dashboard" element={<BI_Dashboard />} />
              <Route path="carousel" element={< ManageUpperCarousel/>} />
              <Route path="carouselmanager" element={< ManageRelatedProducts/>} />
              <Route path="uploadlogo" element={<LogoUpload currentLogo={logoUrl} onUpdateLogo={updateLogo} />} />
            </Routes>
          </Box>
        </Box>
      </Flex>
  );
};

export default AdminDashboard;
