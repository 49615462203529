import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
} from '@chakra-ui/react';
import { generatePdf, printOrder } from '../lib/GenerateDocumentPage.js';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const OrderDetailsModal = ({ isOpen, onClose, order, onOpenShippingModal }) => {
  const toast = useToast();
  const [editedOrder, setEditedOrder] = useState(null);
  const [editableItemIndex, setEditableItemIndex] = useState(null); // Track the index of the editable item

  // Synchronize editedOrder with the incoming order prop
  useEffect(() => {
    if (order) {
      setEditedOrder(order);
    }
  }, [order]);

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...editedOrder.items];
    updatedItems[index][field] = parseFloat(value);
    setEditedOrder({ ...editedOrder, items: updatedItems });
  };

  const handleToggleEdit = (index) => {
    setEditableItemIndex(index); // Allow only the clicked item to be edited
  };

  const handleSaveOrderChanges = async () => {
    try {
      // Extract only the fields that need to be sent
      const updatedFields = {
        items: editedOrder.items.map((item) => ({
          _id: item._id, // Ensure backend can identify the item
          quantity: item.quantity,
          price: item.price,
        })),
        status: editedOrder.status, // Include if status is being updated
        notes: editedOrder.notes, // Include if notes are updated
      };
  
      const response = await fetch(`${API_BASE_URL}api/orders/update/${editedOrder._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(updatedFields), // Send only the updated fields
      });
  
      if (!response.ok) {
        throw new Error('Failed to update order');
      }
  
      const updatedOrder = await response.json();
      toast({
        title: 'Alterações salvas',
        description: 'As alterações do pedido foram salvas com sucesso.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
  
      setEditableItemIndex(null); // Exit edit mode after saving
      setEditedOrder(updatedOrder); // Update the order with the response from the backend
    } catch (error) {
      console.error('Error saving order changes:', error);
      toast({
        title: 'Erro',
        description: 'Falha ao salvar as alterações do pedido.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  

  const handleConvertToPdf = () => {
    if (editedOrder) {
      generatePdf(editedOrder);
    }
  };

  const handlePrint = () => {
    if (editedOrder) {
      printOrder(editedOrder);
    }
  };

  if (!editedOrder) {
    return null; // Prevent rendering until editedOrder is populated
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent id="modal1">
        <ModalHeader>
          Detalhes do Pedido
          <Button colorScheme="blue" onClick={handlePrint} mr={3}>
            Print
          </Button>
          <Button colorScheme="blue" onClick={handleConvertToPdf}>
            Convert to PDF
          </Button>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {editedOrder && (
            <Box>
              <Text>
                <strong>Pedido ID:</strong> {editedOrder._id}
              </Text>
              <Text>
                <strong>Status:</strong> {editedOrder.status}
              </Text>
              <Text>
                <strong>Data do pedido:</strong> {new Date(editedOrder.createdAt).toLocaleString()}
              </Text>
              <Text>
                <strong>Cliente:</strong> {editedOrder.userId ? editedOrder.userId.name || '' : ''}
              </Text>
              <Text>
                <strong>Telefone:</strong> {editedOrder.userId ? editedOrder.userId.phone || '' : ''}
              </Text>
              <Text>
                <strong>Email:</strong> {editedOrder.userId ? editedOrder.userId.email || '' : ''}
              </Text>
              <Text>
                <strong>Endereço:</strong>{' '}
                {editedOrder.shippingAddress
                  ? `${editedOrder.shippingAddress.endereco}, ${editedOrder.shippingAddress.numero}, ${editedOrder.shippingAddress.complemento}, ${editedOrder.shippingAddress.bairro}, ${editedOrder.shippingAddress.cidade}, ${editedOrder.shippingAddress.estado}, ${editedOrder.shippingAddress.pais}`
                  : ''}
              </Text>
              <Text>
                <strong>CEP:</strong> {editedOrder.shippingAddress ? editedOrder.shippingAddress.cep : ''}
              </Text>
              <Text>
                <strong>Valor Total dos itens da Compra:</strong> R$
                {editedOrder.items
                  ? editedOrder.items
                      .reduce((total, item) => total + (item.price ? item.price * item.quantity : 0), 0)
                      .toFixed(2)
                  : '0.00'}
              </Text>
              <Text>
                <strong>Valor do frete:</strong> R${editedOrder.shippingCost ? editedOrder.shippingCost.toFixed(2) : '0.00'}
              </Text>
              <Text>
                <strong>Notas:</strong> {editedOrder.notes}
              </Text>
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>Produto</Th>
                    <Th>Cor</Th>
                    <Th>Tamanho</Th>
                    <Th>Quantidade</Th>
                    <Th>Preço Unidade</Th>
                    <Th>Preço Total</Th>
                    <Th>Ações</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {editedOrder.items &&
                    editedOrder.items.map((item, index) => (
                      <Tr key={index}>
                        <Td>{item.product.name}</Td>
                        <Td>
                          <Flex alignItems="center">
                            <Box
                              bg={item.color ? item.color.hex : 'transparent'}
                              w="20px"
                              h="20px"
                              borderRadius="full"
                              ml={2}
                              mr={2}
                              border="1px solid gray"
                            />
                            <Text>{item.color ? item.color.name : ''}</Text>
                          </Flex>
                        </Td>
                        <Td>{item.size ? item.size.name : ''}</Td>
                        <Td>
                          <Input
                            size="sm"
                            value={item.quantity}
                            type="number"
                            onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                            isDisabled={editableItemIndex !== index}
                          />
                        </Td>
                        <Td>
                          <Input
                            size="sm"
                            value={item.price}
                            type="number"
                            onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                            isDisabled={editableItemIndex !== index}
                          />
                        </Td>
                        <Td>R${(item.price * item.quantity).toFixed(2)}</Td>
                        <Td>
                          {editableItemIndex === index ? (
                            <Button
                              colorScheme="green"
                              size="sm"
                              onClick={() => setEditableItemIndex(null)}
                            >
                              Concluir
                            </Button>
                          ) : (
                            <Button
                              colorScheme="blue"
                              size="sm"
                              onClick={() => handleToggleEdit(index)}
                            >
                              Alterar
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
              <Flex mt={4} alignItems="center" justifyContent="space-between">
                <Button colorScheme="blue" onClick={onOpenShippingModal}>
                  Calcular Frete
                </Button>
                <Box>
                  <Button colorScheme="blue" onClick={onClose} mr={3}>
                    Fechar
                  </Button>
                  <Button colorScheme="green" onClick={handleSaveOrderChanges}>
                    Salvar Alteração de Pedido
                  </Button>
                </Box>
              </Flex>
            </Box>
          )}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderDetailsModal;
